import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <div style={{flex: '1 1 auto'}} className={styles.preview}>
    <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '2rem'}}>
      
      {<Img alt={article.title} fluid={article.thumbnail.fluid} style={{width: '100%'}} className={styles.homepage}/>
       
      }
      
    </div>
    <Link to={`/${article.slug}`}>
      <div className={styles.details}>
        <h3 className={styles.previewTitle}>{article.title}</h3>
      </div>
    </Link>
  </div>
)

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Homepageside from '../components/homepageside'

class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulSectionHeader.edges');

    const allItems = {};
    posts.forEach((post) => {

      if (!allItems[post.node.itemType]) {
        allItems[post.node.itemType] = [];
      }
      allItems[post.node.itemType].push(post.node);
      
    });

    return (
      <Layout location={this.props.location} style={{background: '#f5f5f5'}}>
        <div style={{ display: 'flex',  flex: '1 1 auto' }}>
          <Helmet title="Dana Royal" />
          <div className='homeSection' style={{margin: '0 auto', display: 'flex',  flex: '1 1 auto' }}>
            {
              Object.keys(allItems).sort().map((key) => {
                return (
                  <ul className="article-list" key={key} style={{ display: 'grid', flex: '1 1 auto', gap: 0}} >
                    {allItems[key].map((node, i) => {
                      return (
                        <li key={node.slug}  style={{ display: 'flex', background: i % 2 === 0 ? '#e6e1d8': ''}}>
                          <Homepageside article={node} style={{flex: '1 1 auto' }} />
                        </li>
                      )
                    })}
                  </ul>
                )
              })
            }
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulSectionHeader {
      edges {
        node {
          id
          title
          slug
          thumbnail {
            fluid(maxWidth: 1000, maxHeight: 1000, quality: 100, resizingBehavior: PAD) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
